import React from "react";
import {Link, Stack, Typography} from "@mui/material";

const Footer = () => {
    return (
        <Stack className="footer">
            <Typography variant="subtitle1">Copyright &copy;2023, All rights reserved</Typography>
        </Stack>
    );
};

export default Footer;