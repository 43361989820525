import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import NavBar from "../components/navBar";
import Logo from "../components/logo";
import Footer from "../components/footer";
import logo from '../images/bakedHam.png'

const HomePage = () => {
    return(
        <Stack spacing={2} paddingBottom={2}>
            <NavBar selectedType="home" />
            <Stack className="content-block">
                <Stack padding={2} textAlign={"left"}>
                    <Typography color={"#B60000"} variant={"h5"}>The Peace of Wild Things</Typography>
                    <Typography color={"#cdc1ad;"}>
                        When despair for the world grows in me <br />
                        and I wake in the night at the least sound <br />
                        in fear of what my life and my childrens lives may be, <br />
                        I go and lie down where the wood drake <br />
                        rests in his beauty on the water, and the great heron feeds. <br />
                        I come into the peace of wild things <br />
                        who do not tax their lives with forethought <br />
                        of grief. I come into the presence of still water. <br />
                        And I feel above me the day-blind stars <br />
                        waiting with their light. For a time <br />
                        I rest in the grace of the world, and am free. <br />

                        <br />
                        Wendell Berry
                    </Typography>
                </Stack>
                <Stack direction={"row"} justifyContent={"center"}>
                    <img height={800} width={650} src={logo} />
                </Stack>
            </Stack>
            <Footer />
        </Stack>
    )
};

export default HomePage;