import { React, useState, useEffect } from 'react';
import { Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Logo from "./logo";

const NavBar = (props) => {
    const [homeSelected, setHomeSelected] = useState(false);
    const [infoSelected, setInfoSelected] = useState(false);
    const [randumbSelected, setRandumbSelected] = useState(false);
    const [contactSelected, setContactSelected] = useState(false);

    const navigate = useNavigate();

    const selectedColor = "#00ff00"

    const handleSelected = (event, path) => {
        navigate(path)
    }

    useEffect(() => {
        if (props.selectedType === "home") {
            setHomeSelected(true)
        }
        if (props.selectedType === "info") {
            setInfoSelected(true)
        }
        if (props.selectedType === "randumb") {
            setRandumbSelected(true)
        }
        if (props.selectedType === "contact") {
            setContactSelected(true)
        }
    }, [])

    return (
        <Stack direction={"column"} justifyContent={"center"} spacing={2}>
            <Stack direction={"row"} justifyContent={"center"}>
                <Logo />
            </Stack>
            <Stack direction={"row"} justifyContent={"center"} spacing={4}>
                <Button variant="outlined" onClick={event => handleSelected(event, "/")} style={{ backgroundColor: homeSelected ? selectedColor : "transparent", color: homeSelected ? "black" : "white" }}>Home</Button>
                <Button variant="outlined" onClick={event => handleSelected(event, "/info")} style={{ backgroundColor: infoSelected ? selectedColor : "transparent", color: infoSelected ? "black" : "white" }}>InforFuckingMation</Button>
                {/* <Button variant="outlined" onClick={event => handleSelected(event, "/randumb")} style={{ backgroundColor: randumbSelected ? selectedColor : "transparent", color: randumbSelected ? "black" : "white" }}>Randumb</Button> */}
                <Button variant="outlined" onClick={event => handleSelected(event, "/contact")} style={{ backgroundColor: contactSelected ? selectedColor : "transparent", color: contactSelected ? "black" : "white" }}>Contact</Button>
            </Stack>
        </Stack>
    )
};

export default NavBar;