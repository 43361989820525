import { Box, Button, Stack, Typography } from "@mui/material";
import { React, useState } from 'react';
import NavBar from "../components/navBar";
import Footer from "../components/footer";
import ScrollToTop from "../components/scrollToTop";

const Info = () => {
    const [show, setShow] = useState(false);

    const handleShowHide = (event, letter) => {
        setShow(prev => !prev)
    }

    return(
        <Stack spacing={2} paddingBottom={2}>
            <NavBar selectedType="info" />
            <Stack spacing={2} padding={2} direction={"column"} className="content-block">
                <Typography id="back-to-top-anchor" color={"#cdc1ad;"} textAlign={"left"} variant={"body1"}>A ship is always safe at shore but that is not what its built for. <br />Einstein</Typography>
                <Typography color={"#B60000"} textAlign={"left"} variant={"body1"}>Not mine, just one of my favorite things to read</Typography>
                <pre className="cdcPre">
                    _______________________________________________________________________________<br />
                            _   _                                                      _   _<br />
                        ((___))                                                    ((___))<br />
                        [ x x ]                 cDc communications                 [ x x ]<br />
                            \   /                      presents...                     \   /<br />
                            (` ')                                                      (` ')<br />
                            (U)                                                        (U)<br />
                            <br />
                                                The Ethics of Hacking<br />
                                                <br />
                                                    by  Dissident<br />
                                                    <br />
                                        -/- A Cult Publication......1988 -\- <br />
                                            -cDc- CULT OF THE DEAD COW -cDc- <br />
                    _______________________________________________________________________________

	            </pre>
                <Button variant="outlined" sx={{width:50}} onClick={() => handleShowHide()} style={{ backgroundColor: show ? "transparent" : "#B60000", color: show ? "white" : "black" }}>
                    {
                        show === true ?
                        ("Hide"):
                        ("Show")
                    }
                </Button>
                {
                    show === true ? 
                    (<Box>
                        <Box textAlign={"left"} width={700}>
                            <Typography color={"#cdc1ad;"} sx={{textIndent:30}}>
                                "I went up to a college this summer to look around, see if it was where I
                                wanted to go and whatnot.  The guide asked me about my interests, and when I
                                said computers, he started asking me about what systems I had, etc.  And when
                                all that was done, the first thing he asked me was "Are you a hacker?"
                            </Typography>

                            <Typography color={"#cdc1ad;"} sx={{textIndent:30}}>
                                Well, that question has been bugging me ever since.  Just what exactly
                                is a hacker?  A REAL hacker?
                            </Typography>

                            <Typography color={"#cdc1ad;"} sx={{textIndent:30}}>
                                For those who don't know better, the news media (and even comic strips)
                                have blown it way out of proportion...  A hacker, by wrong-definition, can be
                                anything from a computer-user to someone who destroys everything they can get
                                their evil terminals into.
                            </Typography>

                            <Typography color={"#cdc1ad;"} sx={{textIndent:30}}>
                                And the idiotic schmucks of the world who get a Commodore Vic-20 and a
                                300 baud modem (heh, and a tape drive!) for Christmas haven't helped hackers'
                                reputations a damn bit.  They somehow get access to a really cool system and
                                find some files on hacking...  Or maybe a friendly but not-too-cautious
                                hacker helps the loser out, gives him a few numbers, etc.  The schmuck gets
                                onto a system somewhere, lucks up and gets in to some really cool information
                                or programs, and deletes them.  Or some of the more greedy ones capture it, 
                                delete it, and try to sell it to Libya or something.  Who gets the blame?
                            </Typography>

                            <Typography color={"#cdc1ad;"} sx={{textIndent:30}}>
                                The true hackers...that's who.  So what is a true hacker?
                            </Typography>

                            <Typography color={"#cdc1ad;"} sx={{textIndent:30}}>
                                Firstly, some people may not think I am entirely qualified to say,
                                mainly because I don't consider myself a hacker yet.  I'm still learning the
                                ropes about it, but I think I have a pretty damn good idea of what a true
                                hacker is.  If I'm wrong, let one correct me...
                                <br />
                                <br />
                            </Typography>

                            <Typography color={"#cdc1ad;"} sx={{textIndent:30}}>
                                True hackers are intelligent, they have to be.  Either they do really
                                great in school because they have nothing better to do, or they don't do so
                                good because school is terribly boring. And the ones who are bored aren't that
                                way because they don't give a shit about learning anything. A true hacker wants
                                to know everything.  They're bored because schools teach the same dulll things
                                over and over and over, nothing new, nothing challenging.
                            </Typography>

                            <Typography color={"#cdc1ad;"} sx={{textIndent:30}}>
                                True hackers are curious and patient.  If you aren't, how can you work
                                so very hard hacking away at a single system for even one small PEEK at what
                                may be on it?
                            </Typography>

                            <Typography color={"#cdc1ad;"} sx={{textIndent:30}}>
                                A true hacker DOESN'T get into the system to kill everything or to sell
                                what he gets to someone else.  True hackers want to learn, or want to satisfy
                                their curiosity, that's why they get into the system.  To search around inside
                                of a place they've never been, to explore all the little nooks and crannies
                                of a world so unlike the boring cess-pool we live in.  Why destroy something
                                and take away the pleasure you had from someone else?  Why bring down the
                                whole world on the few true hackers who aren't cruising the phone lines with
                                malicious intent?
                            </Typography>

                            <Typography color={"#cdc1ad;"} sx={{textIndent:30}}>
                                True hackers are disgusted at the way things are in this world.  All the
                                wonderful technology of the world costs three arms and four legs to get these
                                days.  It costs a fortune to call up a board in an adjoining state!  So why
                                pay for it?  To borrow something from a file I will name later, why pay for
                                what could be "dirt cheap if it wasn't run by profiteering gluttons"?
                                Why be forced, due to lack of the hellacious cash flow it would require to
                                call all the great places, to stay around a bunch of schmuck losers in your
                                home town?  Calling out and entering a system you've never seen before are
                                two of the most exhilirating experiences known to man, but it is a pleasure
                                that could not be enjoyed were it not for the ability to phreak...
                            </Typography>

                            <Typography color={"#cdc1ad;"} sx={{textIndent:30}}>
                                True hackers are quiet.  I don't mean they talk at about .5 dB, I mean
                                they keep their mouths shut and don't brag.  The number one killer of those
                                the media would have us call hackers is bragging.  You tell a friend, or you
                                run your mouth on a board, and sooner or later people in power will find out
                                what you did, who you are, and you're gone...
                                <br />
                                <br />
                            </Typography>

                            <Typography color={"#cdc1ad;"} sx={{textIndent:30}}>
                                I honestly don't know what purpose this file will serve, maybe someone
                                somewhere will read it, and know the truth about hackers.  Not the lies that
                                the ignorant spread.  To the true hackers out there, I hope I am portraying
                                what you are in this file...  If I am not, then I at least am saying what I
                                think a true hacker should be.  And to those wanna-be's out there who like
                                the label of "HACKER" being tacked onto them, grow up, would ya?
                                <br />
                                <br />
                            </Typography>

                            <Typography color={"#cdc1ad;"} sx={{textIndent:30}}>
                                Oh yeah, the file I quoted from...  It has been done (at least) two
                                times.  "The Hacker's Manifesto" or "Conscience of a Hacker" are the two
                                names I've seen it given.  (A file by itself, and part of an issue of Phrack)
                                Either way, it was written by The Mentor, and it is absolutely the best thing
                                ever written on the subject of hackers.  Read it, it could change your life.
                                <br />
                                <br />
                            </Typography>

                            <Typography color={"#cdc1ad;"}>
                                ===============================================================================
                                (c)opyright 1988  Dissident and cDc Communications &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp; 1/0/88-37<br />
                                All Rights Reserved"
                            </Typography>
                        </Box>
                    </Box>):
                    (<Box></Box>)
                }
            </Stack>

            <ScrollToTop />
            <Footer />
        </Stack>
    )
};

export default Info;