import React, { useState, useEffect } from 'react';
import { TextField, Button, Stack, Snackbar, Alert} from "@mui/material";
import validate from 'validate.js';
import emailjs from 'emailjs-com';

const ContactForm = () => {
    const [snackBarOpen, setSnackBarOpen] = React.useState(false);

    const schema = {
        from_name: {
            presence: { allowEmpty: false, message: 'is required' },
            length: {
                maximum: 128,
            },
        },
        email: {
            presence: { allowEmpty: false, message: 'is required' },
            email: true,
            length: {
                maximum: 300,
            },
        },
        message: {
            presence: { allowEmpty: false, message: 'is required' },
            length: {
                maximum: 1000,
                
            },
        },
    };

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(
            process.env.REACT_APP_CONTACT_SERVICE_ID,
            process.env.REACT_APP_CONTACT_TEMPLATE_ID,
            e.target,
            process.env.REACT_APP_CONTACT_USER_ID
        )
            .then((res) => console.log('SUCCESS!', res.status, res.text))
            .catch(error => console.log('FAILED...', error));

        setFormState(formState => ({
            ...formState,
            isValid: false,
            values: {},
            touched: {},
            errors: {}
        }));

        setSnackBarOpen(true)
    }

    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {},
    });

    useEffect(() => {
        const errors = validate(formState.values, schema);

        setFormState(formState => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {},
        }));
    }, [formState.values]);

    const handleChange = (e) => {
        e.persist();

        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [e.target.name]:
                    e.target.type === 'checkbox'
                        ? e.target.checked
                        : e.target.value,
            },
            touched: {
                ...formState.touched,
                [e.target.name]: true,
            },
        }));
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBarOpen(false);
    };

    const hasError = (field) => formState.touched[field] && formState.errors[field] ? true : false;
    return (
        <form headers='application/json' name="contact-form" onSubmit={sendEmail}>
            <Stack width={1000} padding={5} spacing={2}>
                <TextField
                    placeholder="Name"
                    label="Name"
                    variant="outlined"
                    size="medium"
                    name="from_name"
                    id="from_name"
                    FormHelperTextProps={{style:{color:"red"}}}
                    fullWidth
                    helperText={
                        hasError('from_name') ?
                            (formState.errors.from_name[0]) :
                            (null)
                    }
                    error={hasError('from_name')}
                    onChange={handleChange}
                    type="text"
                    value={formState.values.from_name || ''}
                    inputProps={{ style: { color: "red" }}}
                    sx={{
                        "& .MuiFormLabel-root": {
                            color: 'red',
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                            color: 'red'
                        },
                    }}>
                </TextField>
                    
                <TextField
                    placeholder="E-mail"
                    label="E-mail"
                    variant="outlined"
                    size="medium"
                    name="email"
                    fullWidth
                    helperText={
                        hasError('email') ?
                            (formState.errors.email[0]) :
                            (null)
                    }
                    error={hasError('email')}
                    onChange={handleChange}
                    type="email"
                    value={formState.values.email || ''}
                    inputProps={{ style: { color: "red" } }}
                    sx={{
                        "& .MuiFormLabel-root": {
                            color: 'red'
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                            color: 'red'
                        }
                    }}>
                </TextField>

                <TextField
                    placeholder="Message"
                    label="Message"
                    name="message"
                    multiline={true}
                    rows={8}
                    style={{ color: "red" }}
                    // maxRows={Infinity}
                    helperText={
                        hasError('message') ?
                            (formState.errors.message[0]) :
                            (null)
                    }
                    error={hasError('message')}
                    onChange={handleChange}
                    type="message"
                    value={formState.values.message || ''}
                    inputProps={{ style: { color: "red" } }}
                    sx={{
                        "& .MuiFormLabel-root": {
                            color: 'red'
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                            color: 'red'
                        }
                    }}>
                </TextField>

                <Button size="large" variant="contained" type="submit" color="primary" disabled={!formState.isValid}>Send</Button>
                <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success" sx={{backgroundColor:"#00ff00", width: '100%', color:"black", fontSize:"18px"}}>
                            Email Sent - Thanks Not
                        </Alert>
                </Snackbar>
            </Stack>
        </form>
    )
};

export default ContactForm;