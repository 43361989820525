import { Stack } from "@mui/material";
import React from "react";

const Logo = () => {
    return(
        <Stack color={"#00ff00"}>
            <pre>
                ___________ __             __________         __               ___   ___ ___                 <br />
                \__    ___/|  |__   ____   \______   \_____  |  | __ ____   __| _/  /   |   \_____    _____  <br />  |    |   |  |  \_/ __ \   |    |  _/\__  \ |  |/ // __ \ / __ |  /    ~    \__  \  /     \ <br />  |    |   |   Y  \  ___/   |    |   \ / __ \|    &lt;\  ___// /_/ |  \    Y    // __ \|  Y Y  \<br />  |____|   |___|  /\___  &gt;  |______  /&#40;____  /__|_ \\___  &gt;____ |   \___|_  /&#41;____  /__|_|  /<br />                \/     \/          \/      \/     \/    \/     \/         \/      \/      \/ <br />

            </pre>
        </Stack>
    )
};

export default Logo;