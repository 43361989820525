import React from 'react';
import { Box, Stack } from '@mui/material';
import { BrowserRouter as Router, Route, Link, Routes} from "react-router-dom";
import ReactGA from 'react-ga4';
import HomePage from './pages/homePage';
import Info from './pages/info';
import RanDumb from './pages/randumb';
import Contact from './pages/contact';
import './App.css';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);

function App() {
  return (
    <div className="main-app" align={"center"} style={{backgroundColor: 'black'}}>
      <Box bgcolor={"black"} minWidth={800} maxWidth={1200}>
        <Router>
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route path="info" element={<Info />} />
            <Route path="randumb" element={<RanDumb />} />
            <Route path="contact" element={<Contact />} />
          </Routes>
        </Router>
      </Box>
    </div>
  );
}

export default App;
