import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import NavBar from "../components/navBar";
import Footer from "../components/footer";

const RanDumb = () => {
    return (
        <Stack spacing={2} paddingBottom={2}>
            <NavBar selectedType="randumb" />
            <Stack className="content-block">
                <Typography variant={"body1"}>RanDumb</Typography>
            </Stack>

            <div id="root" width="200">
                <div class="game">
                    <div class="game-board">
                        <div>
                            <div class="board-row">
                                <button class="square"></button>
                                <button class="square"></button>
                                <button class="square"></button>
                            </div>
                            <div class="board-row">
                                <button class="square"></button>
                                <button class="square"></button>
                                <button class="square"></button>
                            </div>
                            <div class="board-row">
                                <button class="square"></button>
                                <button class="square"></button>
                                <button class="square"></button>
                            </div>
                        </div>
                    </div>
                    <div class="game-info">
                        <div>Next player: X</div>
                        <ol>
                            <li><button>Go to game start</button></li>
                            </ol>
                        </div>
                    </div>
                </div>

                <p><canvas id="canvas" width="750" height="500"></canvas></p>
                <img id="pic" src="https://www.thebakedham.com/images/bakedHam.png" hidden="true"></img>
            <Footer />
        </Stack>
    )
};

export default RanDumb;