import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import NavBar from "../components/navBar";
import Footer from "../components/footer";
import ContactForm from "../components/contactForm";

const Contact = () => {
    return(
        <Stack spacing={2} paddingBottom={2}>
            <NavBar selectedType="contact" />
            <Stack className="content-block" padding={2}>
                <Typography variant={"body1"}>ET Phone Home</Typography>
                <ContactForm />
            </Stack>
            <Footer />
        </Stack>
    )
};

export default Contact;